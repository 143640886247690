<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="1026px" @close="closeDialog" :close-on-click-modal="false">
      <template slot="title">
        <div class="dialogTitles"><span></span> <b>历史数据</b></div>
      </template>
      <div>
        <div class="topSearch">
          日期
          <el-date-picker
            v-model="time"
            :clearable="false"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="expireTimeOption"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="small"
            class="selectBox"
            style="width:360px"
          >
          </el-date-picker>
          监测点位
          <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.monitorPointId">
            <el-option
              v-for="item in mointotList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="loadList('reset')"
            >查询</el-button
          >
          <el-button type="info" plain size="small" @click="reset()">重置</el-button>
          <el-button type="primary" plain size="small" @click="exportDefault">导出</el-button>
        </div>
        
        <el-table
          height="500"
          :data="itemList"
          style="margin-top: 10px;"
        >
          <el-table-column
            label="序号"
            type="index"
            width="52"
          >
          </el-table-column>
          <el-table-column prop="equipmentName" label="设备名称" ></el-table-column>
          <el-table-column prop="monitorPointName" label="监测点位"> </el-table-column>
          <el-table-column prop="currVal" label="远传数据">
            <template slot-scope="{ row }"> {{ row.val }}{{ row.unit }} </template>
          </el-table-column>
          <el-table-column prop="collectTime" label="采集时间"> </el-table-column>
          <el-table-column prop="uploadTime" label="上报时间"> </el-table-column>
        </el-table>
        <div class="pageBox">
          <el-pagination
            :current-page="pageParams.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="pageParams.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            style="margin:10px 0 0 0"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import scadaApi from "@/apis/scadaApi";
import { exportMethod } from '@/common/js/exportExcel'
export default {
  data() {
    return {
      pageParams: {
        size: 100,
        current: 1,
        monitorPointId:""
      },
      time: '',
      total: null,
      itemList: [],
      dialogVisible: false,
      mointotList:[],
      expireTimeOption: this.expireTimeOptionFun()
    }
  },
  methods: {
    expireTimeOptionFun() {
      let selectData = []
      return {
        onPick: ({ maxDate, minDate }) => {
          selectData[0] = minDate?.getTime()
          selectData[1] = maxDate?.getTime()
        },
        disabledDate(time) {
          //选择时间
          if (selectData[0] && !selectData[1]) {
            return (
              time.getTime() > selectData[0] + 24 * 60 * 60 * 6 * 1000 ||
              time.getTime() < selectData[0] - 24 * 60 * 60 * 6 * 1000
            )
          } 
          // else {
          //   return (
          //     time.getTime() > Date.now() + 24 * 60 * 60 * 365 * 1000 ||
          //     time.getTime() < Date.now() - 24 * 60 * 60 * 365 * 1000
          //   )
          // }
        }
      }
    },
    exportDefault() {
      // if (this.itemList.length > 0) {
        let obj = JSON.parse(JSON.stringify(this.pageParams))
           if(this.time){
                obj.startTime = this.time[0]
                obj.endTime = this.time[1]
            }
           obj.current = 0
           obj.size = -1
           scadaApi.exportScadaHistory(obj).then(res => {
                exportMethod(res,'历史数据')
           }) 
      // } else {
      //   this.$message.error('暂无数据，无法导出')
      // }
    },
    reset(){
      // 日期
      let dates = new Date()
      let ymd =
        dates.getFullYear() +
        '-' +
        (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
        '-' +
        (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
      let endTime = ymd + ' 23:59:59'
      let startTime = ymd + ' 00:00:00'
      this.time = [startTime, endTime]

      this.pageParams.size = 30
      this.pageParams.current = 1

      if(this.mointotList.length){
        this.pageParams.monitorPointId = this.mointotList[0]['value']
      }

      this.loadList('reset')
    },
    closeDialog() {
      this.time = ''
      this.mointotList = []
      this.itemList = []
      this.pageParams.monitorPointId = ''
      this.pageParams.size = 30
      this.pageParams.current = 1
    },
    async openData(data) {
      // 日期
      let dates = new Date()
      let ymd =
        dates.getFullYear() +
        '-' +
        (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
        '-' +
        (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
      let endTime = ymd + ' 23:59:59'
      let startTime = ymd + ' 00:00:00'
      this.time = [startTime, endTime]
      this.dialogVisible = true

      // 获取采集点
      let mointotList = await scadaApi.selectMonitorPointOptions({eqId:data})
      
      this.mointotList = mointotList.data

      // 
      if(this.mointotList.length){
        this.pageParams.monitorPointId = mointotList.data[0]['value']
        this.loadList()
      }
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.loadList()
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.loadList()
    },
    loadList(reset) {
      if(this.mointotList.length){
        if(reset){
          this.pageParams.size = 30
          this.pageParams.current = 1
        }
        let obj = {
          ...this.pageParams
        }
        if(this.time.length){
          obj.startTime = this.time[0]
          obj.endTime = this.time[1]
        }
        scadaApi.getHistory(obj).then(res => {
          this.itemList = res.data.list
          this.total = res.data.total
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.topSearch{
  color: #0f0f0f;
}
.selectBox{
    width: 200px;
    margin: 0 30px 0 0;
}
.devInfo {
  background: #eaeaea;
  padding: 10px;
  margin: 10px 0;
}
</style>
